<template>
  <QuestionFormalScreen></QuestionFormalScreen>
</template>
<script>
import QuestionFormalScreen from '@/components/realFrame/QuestionFormalScreen';
import { doExit } from '@/api/targetQ/';
import { mapGetters } from 'vuex';

export default {
  name: 'FormalContent',
  components: { QuestionFormalScreen },
  beforeRouteLeave(to, from, next) {
    if (to.query.type !== 'end' && to.query.type !== 'timeout') {
      if (confirm('確定放棄填答問卷嗎？')) {
        this.exitQuestionnaire();
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  computed: {
    ...mapGetters(['getAppUserToken']),
  },
  methods: {
    async exitQuestionnaire() {
      await doExit(this.getAppUserToken).then((res) => {
        console.log(res);
      });
    },
  },
};
</script>
